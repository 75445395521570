//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import am4themes_dark from '@amcharts/amcharts4/themes/dark';

import { mapGetters, mapActions } from "vuex";
import eventBus from "@/plugins/eventBus";
import * as _ from "underscore";
import XLSX from 'xlsx'

am4core.useTheme(am4themes_animated);

export default {
  name: "flows",
  data() {
    return {
      showChart: true,
      showInnerLoadingRadar: false,
      Radar_TimeLine_Chart_data: [],
      column_chart_data: [],
      isCall: false,
      minRadarValue: 0,
      maxRadarValue: 200,
      showTable: true,
      showTopic: true,
      table_data: [],
      loading: false,
      filter: "",
      visibleColumns: ["intent", "topic", "occurance", "percentage"],
      columns: [
        {
          name: "intent",
          required: true,
          label: "Intent name",
          align: "left",
          field: row => row.intent,
          sortable: true
        },
        {
          name: "topic",
          required: true,
          label: "Topic name",
          align: "left",
          field: row => row.topic,
          sortable: true
        },
          {
            name: "occurance",
            required: true,
            label: "ocurrences #",
            align: "center",
            field: row => row.occurance,
            sortable: true
          },
          {
            name: "percentage",
            required: true,
            label: "Av. Confidence Score",
            align: "center",
            field: row => row.percentage,
            format: val => `${val} %`,
            sortable: true
          }
      ],
      columnOnly: false
    };
  },
  watch: {
    visibleColumns(newVal) {
      if (_.contains(newVal, "topic")) {
        this.columns = [
          {
            name: "intent",
            required: true,
            label: "Intent name",
            align: "left",
            field: row => row.intent,
            sortable: true
          },
          {
            name: "topic",
            required: true,
            label: "Topic name",
            align: "left",
            field: row => row.topic,
            sortable: true
          },
          {
            name: "occurance",
            required: true,
            label: "ocurrences #",
            align: "center",
            field: row => row.occurance,
            sortable: true
          },
          {
            name: "percentage",
            required: true,
            label: "Av. Confidence Score",
            align: "center",
            field: row => row.percentage,
            format: val => `${val} %`,
            sortable: true
          }
        ];
      } else {
        this.columns = [
          {
            name: "intent",
            required: true,
            label: "Intent name",
            align: "left",
            field: row => row.intent,
            sortable: true
          },
          {
            name: "occurance",
            required: true,
            label: "Occurance #",
            align: "center",
            field: row => row.occurance,
            sortable: true
          },
          {
            name: "percentage",
            required: true,
            label: "Av. Confidence Score",
            align: "center",
            field: row => row.percentage,
            format: val => `${val} %`,
            sortable: true
          }
        ];
      }
    },
    darkTheme(){
      if (this.radarTimeLineChart) {
        this.radarTimeLineChart.dispose();
      }
      if(this.columnRotatedChart){
        this.columnRotatedChart.dispose()
      }
       if(this.columnOnly === true){
        setTimeout(() => {
        this.generateColumnRotatedSeries()
        }, 200);
      }else{
        setTimeout(() => {
          this.generateRadarTimelineChart()
        }, 200);
      }
    },
    columnOnly(nawVal){
      if (this.radarTimeLineChart) {
        this.radarTimeLineChart.dispose();
      }
      if(this.columnRotatedChart){
        this.columnRotatedChart.dispose()
      }
       if(nawVal === true){
        setTimeout(() => {
        this.generateColumnRotatedSeries()
        }, 200);
      }else{
        setTimeout(() => {
          this.generateRadarTimelineChart()
        }, 200);
      }
    }
  },
  computed: {
    ...mapGetters({
      getFilterDate: "getFilterDate",
      currentAIAccount: "getCurrentAIAccount",
      currentUser: "getCurrentUser",
      darkTheme: "getDarkTheme"
    })
  },
  methods: {
    ...mapActions({
      requestConversationsTopic: "requestConversationsTopic",
      requestIntentStats: "requestIntentStats",
      generateXMLFile: "generateXMLFile"
    }),
    generateRadarTimelineChart() {
      const that = this;
      if(this.darkTheme === true){
        am4core.useTheme(am4themes_dark);
      }else{
        am4core.unuseTheme(am4themes_dark);
      }
      const colorSet = new am4core.ColorSet();

      const chart = am4core.create(that.$refs.radarTimeLine, am4charts.RadarChart);
      chart.hiddenState.properties.opacity = 0;

      chart.startAngle = 270 - 180;
      chart.endAngle = 270 + 180;

      chart.padding(5, 15, 5, 10);
      chart.radius = am4core.percent(40);
      chart.innerRadius = am4core.percent(40);

      // year label goes in the middle
      const yearLabel = chart.radarContainer.createChild(am4core.Label);
      yearLabel.horizontalCenter = "middle";
      yearLabel.verticalCenter = "middle";
      yearLabel.fill = am4core.color("#673AB7");
      yearLabel.fontSize = 30;

      // zoomout button
      const { zoomOutButton } = chart;
      zoomOutButton.dx = 0;
      zoomOutButton.dy = 0;
      zoomOutButton.marginBottom = 15;
      zoomOutButton.parent = chart.rightAxesContainer;

      // scrollbar
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.rightAxesContainer;
      chart.scrollbarX.orientation = "vertical";
      chart.scrollbarX.align = "center";
      chart.scrollbarX.exportable = false;

      // vertical orientation for zoom out button and scrollbar to be positioned properly
      chart.rightAxesContainer.layout = "vertical";
      chart.rightAxesContainer.padding(120, 20, 120, 20);

      // category axis
      const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.dataFields.category = "intent";

      const categoryAxisRenderer = categoryAxis.renderer;
      const categoryAxisLabel = categoryAxisRenderer.labels.template;
      categoryAxisLabel.location = 0.5;
      categoryAxisLabel.radius = 28;
      categoryAxisLabel.relativeRotation = 90;

      categoryAxisRenderer.fontSize = 11;
      categoryAxisRenderer.minGridDistance = 10;
      categoryAxisRenderer.grid.template.radius = -25;
      categoryAxisRenderer.grid.template.strokeOpacity = 0.05;
      categoryAxisRenderer.grid.template.interactionsEnabled = false;

      categoryAxisRenderer.ticks.template.disabled = true;
      categoryAxisRenderer.axisFills.template.disabled = true;
      categoryAxisRenderer.line.disabled = true;

      categoryAxisRenderer.tooltipLocation = 0.5;
      categoryAxis.tooltip.defaultState.properties.opacity = 0;

      // value axis
      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = that.minRadarValue;
      valueAxis.max = that.maxRadarValue;
      valueAxis.strictMinMax = true;
      valueAxis.tooltip.defaultState.properties.opacity = 0;
      valueAxis.tooltip.animationDuration = 0;
      valueAxis.cursorTooltipEnabled = true;
      valueAxis.zIndex = 10;

      const valueAxisRenderer = valueAxis.renderer;
      valueAxisRenderer.axisFills.template.disabled = true;
      valueAxisRenderer.ticks.template.disabled = true;
      valueAxisRenderer.minGridDistance = 20;
      valueAxisRenderer.grid.template.strokeOpacity = 0.05;

      // series
      const series = chart.series.push(new am4charts.RadarColumnSeries());
      series.columns.template.width = am4core.percent(90);
      series.columns.template.strokeOpacity = 0;
      series.dataFields.valueY = "value";
      series.dataFields.categoryX = "intent";
      series.tooltipText = "{categoryX}:{valueY.value}";

      // this makes columns to be of a different color, depending on value
      //
      series.heatRules.push({
        target: series.columns.template,
        property: "fill",
        minValue: that.minRadarValue,
        maxValue: that.maxRadarValue,
        min: am4core.color("#673AB7"),
        max: am4core.color("#F44336"),
        dataField: "valueY"
      });

      // cursor
      const cursor = new am4charts.RadarCursor();
      chart.cursor = cursor;
      cursor.behavior = "zoomX";

      cursor.xAxis = categoryAxis;
      cursor.innerRadius = am4core.percent(10);
      cursor.lineY.disabled = true;

      cursor.lineX.fillOpacity = 0.2;
      cursor.lineX.fill = am4core.color("#000000");
      cursor.lineX.strokeOpacity = 0;
      cursor.fullWidthLineX = true;

      chart.data = generateRadarData();

      function generateRadarData() {
        const data = [];
        let i = 0;
        for (const topic in that.Radar_TimeLine_Chart_data) {
          const topicData = that.Radar_TimeLine_Chart_data[topic];

          topicData.forEach(intent => {
            const rawDataItem = {
              intent: intent[0],
              value: intent[1]
            };

            data.push(rawDataItem);
          });

          createRange(topic, topicData, i);
          i++;
        }
        return data;
      }

      function createRange(name, topicData, index) {
        const axisRange = categoryAxis.axisRanges.create();
        axisRange.axisFill.interactionsEnabled = true;
        axisRange.text = name;
        // first topicData
        axisRange.category = topicData[0][0];
        // last country
        axisRange.endCategory = topicData[topicData.length - 1][0];

        // every 3rd color for a bigger contrast
        axisRange.axisFill.fill = colorSet.getIndex(index * 3);
        axisRange.grid.disabled = true;
        axisRange.label.interactionsEnabled = true;
        axisRange.label.bent = true;
        axisRange.label.hidden = true;

        const { axisFill } = axisRange;
        axisFill.innerRadius = -0.001; // almost the same as 100%, we set it in pixels as later we animate this property to some pixel value
        axisFill.radius = -20; // negative radius means it is calculated from max radius
        axisFill.disabled = false; // as regular fills are disabled, we need to enable this one
        axisFill.fillOpacity = 1;
        axisFill.togglable = true;

        axisFill.showSystemTooltip = true;
        axisFill.readerTitle = "click to zoom";
        axisFill.cursorOverStyle = am4core.MouseCursorStyle.pointer;

        axisFill.events.on("hit", event => {
          const { dataItem } = event.target;
          if (!event.target.isActive) {
            categoryAxis.zoom({ start: 0, end: 1 });
          } else {
            categoryAxis.zoomToCategories(dataItem.category, dataItem.endCategory);
          }
        });

        // hover state
        const hoverState = axisFill.states.create("hover");
        hoverState.properties.innerRadius = -10;
        hoverState.properties.radius = -25;

        const axisLabel = axisRange.label;
        axisLabel.location = 0.5;
        axisLabel.fill = am4core.color("#ffffff");
        axisLabel.radius = 3;
        axisLabel.relativeRotation = 0;
      }
      // const slider = chart.createChild(am4core.Slider);
      // slider.start = 1;
      // slider.exportable = false;
      // slider.events.on("rangechanged", () => {
      //   const { start } = slider;

      //   chart.startAngle = 270 - start * 179 - 1;
      //   chart.endAngle = 270 + start * 179 + 1;

      //   valueAxis.renderer.axisAngle = chart.startAngle;
      // });
      that.radarTimeLineChart = chart;
      // Enable export
      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.filePrefix = `EVO_Dynamics_report-${that.currentAIAccount.name}-Intents Overview-${that.getFilterDate.start}-to-${that.getFilterDate.end}`;
    },
    generateColumnRotatedSeries(){
      const that = this;
      if(this.darkTheme === true){
        am4core.useTheme(am4themes_dark);
      }else{
        am4core.unuseTheme(am4themes_dark);
      }
      const chart = am4core.create(that.$refs.columnRotatedSeries, am4charts.XYChart);
              
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.marginTop = 30
      console.log("chart.scrollbarX " , chart.scrollbarX)

      // Add data
      chart.data = that.column_chart_data

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "intent";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.renderer.labels.template.horizontalCenter = "right";
      categoryAxis.renderer.labels.template.verticalCenter = "middle";
      categoryAxis.renderer.labels.template.rotation = 270;
      categoryAxis.tooltip.disabled = true;
      categoryAxis.renderer.minHeight = 110;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.minWidth = 50;

      // Create series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.sequencedInterpolation = true;
      series.dataFields.valueY = "value";
      series.dataFields.categoryX = "intent";
      series.tooltipText = "[{categoryX}: bold]{categoryX}[/]";
      series.columns.template.strokeWidth = 0;

      series.tooltip.pointerOrientation = "vertical";

      series.columns.template.column.cornerRadiusTopLeft = 10;
      series.columns.template.column.cornerRadiusTopRight = 10;
      series.columns.template.column.fillOpacity = 0.8;

      // on hover, make corner radiuses bigger
      let hoverState = series.columns.template.column.states.create("hover");
      hoverState.properties.cornerRadiusTopLeft = 0;
      hoverState.properties.cornerRadiusTopRight = 0;
      hoverState.properties.fillOpacity = 1;

      series.columns.template.adapter.add("fill", function(fill, target) {
        return chart.colors.getIndex(target.dataItem.index);
      });

      // Cursor
      chart.cursor = new am4charts.XYCursor();

      that.columnRotatedChart = chart;
      // Enable export
      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.filePrefix = `EVO_Dynamics_report-${that.currentAIAccount.name}-Intents Overview Columns-${that.getFilterDate.start}-to-${that.getFilterDate.end}`;
    
    },
    exportXls(){
      let fileName = `EVO_Dynamics_report${this.currentAIAccount.name}_${this.getFilterDate.start}_to_${this.getFilterDate.end}_message_report_Intents_overview`
      var data = _.map(this.table_data , (record , index) => {
      let intentInfo = {}
        intentInfo['S. No'] =  index + 1
        
        intentInfo['Intent Name'] =  record.intent
        if(_.contains(this.visibleColumns, "topic")){
          intentInfo['Topic Name'] =  record.topic
        }
        intentInfo['Occurrences #'] =  record.occurance
        intentInfo['Av. Confidence Score'] =  record.percentage

        return intentInfo
      });
      /* this line is only needed if you are not adding a script tag reference */
      /* make the worksheet */
      var ws = XLSX.utils.json_to_sheet(data);
      // /* add to workbook */
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "People");
      // /* generate an XLSX file */
      XLSX.writeFile(wb, `${fileName}.xlsx`);
    },
    viewIntentMessages(row){
      let routeQuery = this.$route.query
      routeQuery['intent'] = row
      this.$router.push({name: 'conversationsMessages' , query: routeQuery})
    },
    onDateUpdate(date) {
      const that = this;
      if (that.isValid(date.start) && that.isValid(date.end)) {
        const data = {
          aiAccount: that.currentAIAccount.id,
          startdate: date.start,
          enddate: date.end,
          from: "onDateUpdate"
        };
        that.isCall = false;
        that.updateIntentOverviewData(data);
      }
    },
    updateIntentOverviewData(data) {
      const that = this;
      if (!that.isCall) {
        that.showChart = true;
        that.showInnerLoadingRadar = true;
        that.showPagination = false;
        that.isCall = true;
        if (that.radarTimeLineChart) {
          that.radarTimeLineChart.dispose();
        }
        that.loading = true;
        that.table_data = [];
        that.requestConversationsTopic(data).then(res => {
          that.generateDataForColumnChart(res.result)
          that.Radar_TimeLine_Chart_data = res.result;
          that.minRadarValue = res.min;
          that.maxRadarValue = res.max;
          setTimeout(() => {
            that.showInnerLoadingRadar = false;
            that.generateRadarTimelineChart();
          }, 100);
        }).catch(err => {
          that.showChart = false;
          that.showInnerLoading = false;
        }).finally(() => {
          that.isCall = false;
          that.updateIntentTable(data);
        });
      }
    },
    updateIntentTable(data) {
      const that = this;
      if (!that.isCall) {
        that.loading = true;
        that.table_data = [];
        data.slotMins = 60;
        that.requestIntentStats(data).then(response => {
          if (response.result.length > 0) {
            that.parseTableData(response.result);
          } else {
            that.showTable = false;
          }
        }).catch(err => {
          that.showTable = false;
        }) .finally(() => {
          that.loading = false;
        });
      }
    },
    parseTableData(data) {
      const that = this;
      const formatted_data = [];
      _.forEach(data, record => {
        const intent = record.intent;
        let confidanceScore = _.reduce(_.pluck(record.valueList, "confidanceScore"),function(memo, num) {
          return memo + num;
        },0);
        confidanceScore = Number(confidanceScore.toFixed(2))
        const occurance = _.reduce(_.pluck(record.valueList, "occurance"), function(memo, num) {
          return memo + num;
        },0);
        const percentage = Number(((confidanceScore / occurance) * 100).toFixed(2))
        formatted_data.push({
          intent: record.intent,
          topic: record.topic,
          occurance,
          percentage
        });
      });
      that.table_data = formatted_data;
    },
    generateDataForColumnChart(result){
      let intent_occurance = []
      for(var topic in result){
        for(var intent in result[topic]){
          intent_occurance.push({
            intent: result[topic][intent][0],
            value: result[topic][intent][1]
          })
        }
      }
      this.column_chart_data = intent_occurance
    },
    onAccountUpdate(account) {
      const that = this;
      const data = {
        aiAccount: account.id,
        startdate: that.getFilterDate.start,
        enddate: that.getFilterDate.end,
        from: "onAccountUpdate"
      };
      that.isCall = false;
      that.updateIntentOverviewData(data);
    },
    isValid(value) {
      if (value === undefined || value === null || (typeof value === "object" && Object.keys(value).length === 0) || (typeof value === "string" && value.trim().length === 0)) {
        return false;
      }
      return true;
    }
  },
  mounted() {
    const that = this;
    const data = {
      aiAccount: that.currentAIAccount.id,
      startdate: that.getFilterDate.start,
      enddate: that.getFilterDate.end,
      from: "mounted"
    };
    that.updateIntentOverviewData(data);
  },
  created() {
    eventBus.$on("updateDate", this.onDateUpdate);
    eventBus.$on("updateAIAccount", this.onAccountUpdate);
  },
  beforeDestroy() {
    const that = this;
    if (that.radarTimeLineChart) {
      that.radarTimeLineChart.dispose();
    }
    eventBus.$off("updateDate", this.onDateUpdate);
    eventBus.$off("updateAIAccount", this.onAccountUpdate);
  },
  destroyed() {
    eventBus.$off("updateDate", this.onDateUpdate);
    eventBus.$off("updateAIAccount", this.onAccountUpdate);
  }
};
